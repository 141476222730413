<template>
  <v-dialog v-model="showModal" max-width="800">
    <v-card>
      <v-card-title>
        <span class="headline">
          {{ $tc('title.erro', 2) }}
        </span>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="cabecalho"
          :items="erros"
          hide-default-footer>
          <template v-slot:item.erro="{ item }">
            {{ item.texto }}
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="accent" text @click.native="confirma">{{ $t('label.ok') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  data() {
    return {
      showModal: false,
      erros: [],
      cabecalho: [
        {
          text: this.$tc('label.celula', 1), value: 'celula', sortable: false, width: '20%',
        },
        { text: this.$tc('label.erro', 1), value: 'erro', sortable: false },
      ],
      mapaCampos: {},
      erro: '',
    };
  },
  props: {
    getCelulaEntidade: {
      type: Function,
      required: true,
    },
  },
  methods: {
    confirma() {
      this.showModal = false;
    },
    apresentaErrosServidor(res) {
      const objErros = JSON.parse(res.bodyText);

      if (objErros.erros_planilha) {
        this.showModal = true;
        this.apresentaErrosPlanilha(objErros);
      } else {
        this.apresentaOutrosErros(res);
      }
    },
    apresentaOutrosErros(res) {
      this.$toast(res.data.error);
    },
    apresentaErrosPlanilha(objErros) {
      this.erros = [];
      const { linhas } = objErros;
      const indices = Object.keys(linhas);
      indices.forEach((indice) => {
        const erros = linhas[indice];
        this.montaErros(erros, indice);
      });
    },
    montaErros(erros, indice) {
      erros.forEach((item) => {
        const { entidade, tipo } = item;
        const celula = this.montaCelulaErro(entidade, indice);

        const erro = { ...item };
        erro.entidade = this.mapaCampos[item.entidade];

        if (tipo === 'padrao') {
          const texto = this.montaErroEntidadeInvalida(erro);
          this.erros.push({ celula, texto });
        } else if (tipo === 'obrigatorio') {
          const texto = this.montaErroObrigatorio(erro);
          this.erros.push({ celula, texto });
        } else if (tipo === 'valor_negativo') {
          const texto = this.montaErroValorNegativo(erro);
          this.erros.push({ celula, texto });
        } else if (tipo === 'duplicado') {
          const texto = this.montaErroValorDuplicado(erro);
          this.erros.push({ celula, texto });
        }
      });
    },
    montaErroEntidadeInvalida(erro) {
      return this.$t('errors.planilha.entidade_invalida', erro);
    },
    montaErroObrigatorio(erro) {
      return this.$t('errors.planilha.obrigatorio', erro);
    },
    montaErroValorNegativo(erro) {
      return this.$t('errors.planilha.valor_menor_zero', erro);
    },
    montaErroValorDuplicado(erro) {
      return this.$t('errors.planilha.informacao_duplicada', erro);
    },
    montaCelulaErro(entidade, indice) {
      return `${this.getCelulaEntidade(entidade)}${indice}`;
    },
    montaMapaCampos() {
      this.mapaCampos.grupoeconomico = this.$tc('label.grupoeconomico', 1);
      this.mapaCampos.tipo_investimento = this.$tc('label.tipo_investimento', 1);
      this.mapaCampos.descricao = this.$tc('label.descricao', 1);
      this.mapaCampos.valor = this.$tc('label.valor', 1);
      this.mapaCampos.hierarquia = this.$tc('label.hierarquia', 1);
    },
  },
  mounted() {
    this.montaMapaCampos();
  },
};
</script>
